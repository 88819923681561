@import "../../../../styles/variables.scss";
@import "../../../../styles/colors.scss";

.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 80px;

	@media (max-width: $tablet) {
		margin-top: 60px;
	}

	@media (max-width: $tabletSmall) {
		margin-top: 40px;
	}
}

.body {
	display: flex;
	flex-direction: column;
	padding-bottom: 100px;
	border-bottom: 1px solid #D7D7D7;
	margin-bottom: 125px;

	@media (max-width: $tablet) {
		padding-bottom: 0px;
		margin-bottom: 80px;
		border: none;
	}
}

.header {
	margin-bottom: 48px;
	padding: 0;

	@media (max-width: $tablet) {
		margin-bottom: 32px;
	}
}

.title {
	font-size: 36px;
	font-weight: 700;
	line-height: 111%;
	margin-bottom: 26px;

	@media (max-width: $tablet) {
		font-size: 24px;
		font-weight: 800;
		line-height: 116%;
	}
}

.link {
	display: inline-block;
	margin: 60px auto;
}
.block_img {
	position: relative;
	width: 100%;
	margin-bottom: 48px;

	&::after {
		content: '';
		display: block;
		padding-bottom: 47%;
		width: 100%;
	}

	@media (max-width: $tablet) {
		margin-bottom: 36px;
	}
}

.content {
	p {
		padding-bottom: 20px;
	}
}

.img {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 16px;

	@media (max-width: $tablet) {
		border-radius: 8px;
	}
}