@import "./../../../../styles/variables";
@import "./../../../../styles/colors";

.items {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 56px;

	@media (max-width: $tablet) {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 26px;
	}

	@media (max-width: $tabletSmall) {
		grid-template-columns: 1fr;
	}
}