@import "src/styles/variables";
@import  "src/styles/globals.scss";

.Navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  grid-gap: 16px;
  margin-top: 0px;
  @media (max-width: $tablet) {
    justify-content: space-between;
    width: 100%;
  }
}

.Light {
  .Prev,
  .Next {
    svg {
      stroke: $black;
    }

    &:hover:disabled {
      svg {
        stroke: #fff;
      }
    }
  }
}

.Prev,
.Next {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  border: 1px solid $primary;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    svg {
      stroke: $primary;
    }
  }

  &:hover:disabled {
    svg {
      stroke: gray;
    }
  }

  &:disabled {
    opacity: 0.1;
  }

  svg {
    transition: all 100ms;
    fill: none;
    stroke: none;
  }
}
