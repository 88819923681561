

.root {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d7d7d7;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  gap: 60px;
}

.title {
  font-family: "Manrope";
  font-weight: 800;
  font-size: 36px;
  line-height: 60px;

}


.wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.titleColor {
  color: #1C7C54;;
}
.desc {
  max-width: 340px;
  max-height: 108px;
  overflow: hidden;
  font-weight: 500;
  font-size: 17px;
  line-height: 35px;
  color: #1C7C54;;
  text-align: justify;
}

.text {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #5e6166;
  text-align: justify;

}
b{
  text-align: justify;
}
p{
  text-align: justify;
}

.images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.firstImg {
  width: 544px;
  height: 544px;
  background-position: center;
  border-radius: 10px;
}
.secondImg {
  width: 544px;
  height: 264px;
  border-radius: 10px;
}
.threeImg {
  width: 544px;
  height: 264px;
  border-radius: 10px;
}

.fourImage {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
  .title {
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
  }

  .desc {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }

  .text {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
  }

  .firstImg {
    width: 413px;
    height: 392px;
  }
  .secondImg {
    width: 413px;
    height: 188px;
  }

  .threeImg {
    width: 413px;
    height: 188px;
  }
  .fourImg {
    width: 190px;
    height: 188px;
  }
  .fiveImg {
    width: 190px;
    height: 188px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
  .root {
    gap: 20px;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .title {
    font-weight: 800;
    font-size: 24px;
    line-height: 38px;
  }

  .wrap {
    gap: 16px;
  }
  .titleColor {
    color: #1C7C54;;
  }
  .desc {
    font-size: 13px;
    line-height: 25px;
  }

  .text {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  }



  // .fourImage {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 16px;
  // }

  .firstImg {
    width: 252px;
    height: 240px;
  }
  .secondImg {
    width: 252px;
    height: 114px;
  }

  .threeImg {
    width: 252px;
    height: 114px;
  }
  .fourImg {
    width: 118px;
    height: 114px;
  }
  .fiveImg {
    width: 118px;
    height: 114px;
  }
}


@media only screen and (min-width: 360px) and (max-width: 600px) {
  .root {
    gap: 48px;
    border-bottom: 2px solid #d7d7d7;
    padding-bottom: 5px;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .title {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
  }

  .wrap {
    gap: 16px;
  }
  .titleColor {
    color:#1C7C54;;
  }
  .desc {
    font-weight: 500;
    font-size: 13px;
    line-height: 26px;
  }

  .text {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  }

  .images {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .fourImage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .firstImg {
    width: 328px;
    height: 204px;
  }
  .secondImg {
    width: 156px;
    height: 148px;
    margin-top: 42px;
  }

  .threeImg {
    width: 156px;
    height: 148px;
  }
  .fourImg {
    width: 156px;
    height: 148px;
  }
  .fiveImg {
    width: 156px;
    height: 148px;
    margin-top: -42px;
  }
}
