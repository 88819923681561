@import "src/styles/variables";
@import  "src/styles/root.scss";

.section {
	display: flex;
	margin-bottom: 100px;
	box-sizing: border-box;
	@media (max-width: $tablet) {
		margin-bottom: 80px;
		flex-direction: column;
	}
}  

.title{
	font-family: 'Manrope'; 
	color: #010101;
	font-style: normal;
	font-weight: 800;
	font-size: 44px;
	line-height: 64px;
	text-align: left;
	font-feature-settings: 'liga' off;
	margin-bottom: 28px;
	span {
		color: $primary;
	}

	@media (max-width: $tablet) {
		font-weight: 800;
		font-size: 19px;
		line-height: 28px;
	}
	
	@media (max-width: $desktopSmall) {
		font-size: 20px;
		line-height: 38px;
	}
	
	@media (max-width: $desktop) {
		font-weight: 800;
		font-size: 33px;
		line-height: 50px;
	}
}
.text {
	font-size: 17px;
	font-weight: 500;
	line-height: 164%;
	color: $primary;
}
.left {
	width: 45%;
	padding-right: 62px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: $tablet) {
		width: 100%;
		padding: 0;
		margin-bottom: 20px;
	}
}
.right {
	width: 55%;

	@media (max-width: $tablet) {
		width: 100%;
	}
}
.swiper {
}
.header {
	margin-bottom: 76px;

	@media (max-width: $tablet) {
		margin-bottom: 48px;
	}
}

.content {
	margin-bottom: 24px;
}
.bl_img {
	border-radius: 16px;
	overflow: hidden;
	width: 100%;
	position: relative;
	&::after {
		content: '';
		padding-bottom: 75%;
		display: block;

	}
	img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.footer {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;

	a {
		color: white;
	}
}

.button {
	padding: 16px 38px !important;
	width: max-content !important;
	font-size: 15px !important;
	background-color: transparent;
	border: 1px solid $primary;
	background:  $primary;
	border-radius: 8px;
	color: $white;
	padding: 12px 24px;

	@media (max-width: $tablet) {
		margin-top: 20px;
	}
}

.info {
	margin-bottom: 24px;
}
