.root {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 35px;
  gap: 14px;
  border: 1px solid #00AB66;
  box-shadow: 0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 40px #EEEEEE;
  border-radius: 16px;
  border-radius: 16px;
  max-width: 336px;
  max-height: 316px;
}

.rootRU{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 35px;
  gap: 14px;
  border: 1px solid #00AB66;
  box-shadow: 0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 40px #EEEEEE;
  border-radius: 16px;
  border-radius: 16px;
  max-width: 336px;
  max-height: 316px;
}

.rootAbout{
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 15px;
  gap: 5px;
  border: 1px solid #00AB66;
  box-shadow: 0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 40px #EEEEEE;
  border-radius: 16px;
  border-radius: 16px;
  max-width: 250px;
  max-height: 204px;
}

.icon {
  max-width: 60px;
  max-height: 60px;
}

.iconAbout{
  width: 40px;
  height: 40px;
}
.titleAbout {
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #010101;
}

.title {
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 28px;
  color: #010101;
}

.subtitle {
  display: flex;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #5E6166;;
}
.subtitleRU {
  display: flex;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5E6166;;
}

@media only screen and (min-width: 824px) and (max-width: 1100px) {
  .root {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    gap: 8px;
    border: 1px solid #00AB66;
    border-radius: 10px;
    width: 172px;
    height: 171px;
  }
  .icon {
    width: 31px;
    height: 24px;
  }
  .title {
    font-size: 15px;
    line-height: 24px;
    font-weight: 800;
  }
  .subtitle {
      font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  }
  .subtitleRU {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px
  }
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
  .root {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    gap: 8px;
    border: 1px solid #00AB66;
    border-radius: 5px;
    width: 160px;
    height: 160px;
  }
  .icon {
    width: 30px;
    height: 25px;
  }
  .title {
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  .subtitle {
    font-size: 9px;
    line-height: 16px;

    letter-spacing: 0.4px;
  }
  .subtitleRU {
    font-size: 9px;
    line-height: 16px;

    letter-spacing: 0.4px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .root {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 5px;
    gap: 8px;
    border: 1px solid #00AB66;
    border-radius: 5px;
    width: 152px;
    height: 140px;
  }

  .rootRU {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 5px;
    gap: 8px;
    border: 1px solid #00AB66;
    border-radius: 5px;
    width: 100%;
    height: 170px;
  }
  .icon {
    width: 15px;
    height: 15px;
  }
  .title {
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  .subtitle {
    font-size: 9px;
    line-height: 16px;

    letter-spacing: 0.4px;
  }

  .subtitleRU {
    font-size: 8px;
    line-height: 16px;

    letter-spacing: 0.4px;
  }
  
}
