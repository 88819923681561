@import "../../styles/root.scss";
@import "../../styles/variables.scss";



.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 100px;
	text-align: center;
	color: white;
	transition: opacity 2000ms ease-in;


}

.left {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 40px;
	width: 432px;
	height: 340px;
}

.button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 48px;
}


.img {
	width: 528px;
	height: 498px;
	border-radius: 10px;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
	.container {
		gap: 65px;
	}

	.button {

		gap: 28px;
	}

	.img {
		width: 440px;
		height: 412px;
		border-radius: 10px;
	}

	.left {

		width: 100%;
	}
}


@media only screen and (min-width: 600px) and (max-width: 824px) {
	.button {
		gap: 28px;
	}

	.img {
		width: 272px;
		height: 308px;
		border-radius: 10px;
	}

	.left {
		width: 319px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 30px;
	}
}


@media only screen and (min-width: 360px) and (max-width: 600px) {
	.left {
		border: 1px solid #1C7C54;
		border-radius: 10px;
		height: 100%;
		padding: 15px;
		width: 100%;
	}

	.img {
		width: 328px;
		height: 308px;
		border-radius: 10px;
	}

	.container {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		margin-top: 40px;
		gap: 24px;
	}

	.button {
		display: flex;
		align-items: flex-end;
		padding-bottom: 10px;
		gap: 28px;
		margin-top: -10px;
	}
}