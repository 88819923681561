@import "../../styles/root.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.Card{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.row{
    display: flex;
    flex-direction: row;
    // margin-top: 40px;
}
.partnerLogo{
    object-fit: cover;
    height: 153px;
}


.mobile {
    display: none;
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
    .Card{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
        justify-items: center ;
    } 
    .partnerLogo{
        width: 100%;
        object-fit: cover;
    }
}



@media only screen and (min-width: 360px) and (max-width: 600px) {
    .Card{
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px;
        justify-items: center;
    } 

    .partnerLogo{
        width: 100%;
        object-fit: contain;
    }

    .row{
        width: 360px;
        display: flex;
        flex-direction: column;
        // margin-top: 40px;
    }
    .partnerLogo{
        object-fit: contain ;
        height: 150px;
    }
    .mobile{
        display: block;
        width: 240px;
        height: 140px;
    }
    // .partnerLogo{
    //     display: none;
    // }

    .risha{
        margin-left: 30px;
        object-fit: contain;
    }
}