@import "src/styles/variables";
@import  "src/styles/root.scss";

.section {
	display: flex;
	margin-bottom: 100px;
	box-sizing: border-box;
	@media (max-width: $tablet) {
		margin-bottom: 80px;
		flex-direction: column;
	}
}  

.left {
	width: 45%;
	padding-right: 62px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	@media (max-width: $tablet) {
		width: 100%;
		padding: 0;
		margin-bottom: 20px;
	}
}
.right {
	width: 55%;

	@media (max-width: $tablet) {
		width: 100%;
	}
}

.content {
	margin-bottom: 24px;
}
.title{
	font-family: 'Manrope'; 
	color: #010101;
	font-style: normal;
	font-weight: 800;
	font-size: 44px;
	line-height: 64px;
	text-align: left;
	font-feature-settings: 'liga' off;
	margin-bottom: 28px;
	span {
		color: $primary;
	}

	@media (max-width: $tablet) {
		font-weight: 800;
		font-size: 19px;
		line-height: 28px;
	}
	
	@media (max-width: $desktopSmall) {
		font-size: 20px;
		line-height: 38px;
	}
	
	@media (max-width: $desktop) {
		font-weight: 800;
		font-size: 33px;
		line-height: 50px;
	}
}
.text {
	font-size: 17px;
	font-weight: 500;
	line-height: 164%;
	color: $primary;
}

.button {
	padding: 16px 38px !important;
	width: max-content !important;
	font-size: 15px !important;
	background-color: transparent;
	border: 1px solid $primary;
	background:  $primary;
	border-radius: 8px;
	color: $white;
	padding: 12px 24px;
    margin-top: 28px;

	@media (max-width: $tablet) {
		margin-top: 20px;
		padding: 12px 20px !important;
	}
}


.items {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 48px;

	padding-bottom: 35px;

	@media (max-width: $tablet) {
		// grid-gap: 16px;
	}

	:global(.swiper-pagination-bullet ) {
		background-color: $white;
		width: 8px;
		height: 8px;
		display: inline-block;
		border-radius: 50%;
		background: $primary;
		margin: 0 4px;
		cursor: pointer;
	
		@media (max-width: $tablet) {
		  margin: 0 2px;
		}
	}
	:global(.swiper-pagination-bullet-active ) {
		background: $primary;
		cursor: pointer;

	@media (max-width: $tablet) {
			margin: 0 2px;
		}
	}


	:global(.swiper-pagination-bullets ) {
		bottom: 0px;
	}
}
.bullets {
	// position: relative;
	// position: absolute;
	// bottom: 0;
	// left: 50%;
	// transform: translate(0%, -50%);
	// width: 100%;
	height: 100%;
	height: max-content;
	z-index: 1;
	// margin-bottom: 15px;
}
