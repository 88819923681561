.root {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 110px;
	background: #fff;
	display: flex;
	flex-direction: row;
	z-index: 8999 !important;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
	// margin-bottom: 110px;
}

.header {
	width: 100%;
	display: flex;
	justify-content: center;
}

.wrap {
	position: relative;
	width: 1104px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center; // gap: 35px;
}

.header {
	width: 100%;
	display: grid;
	float: right;
}

.header ul {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
	background-color: #fff;

	a {
		cursor: pointer;
	}
}

.button {
	margin-left: 20px;
	margin-top: 5px;
}

.header li a {
	display: block;
	padding: 20px 20px;
	text-decoration: none;

}

.header li {
	float: left;
}

.header li a {
	padding: 15px 8px;
}

.header li a:hover,
.header .menuBtn:hover {
	background-color: #f4f4f4;
}

.widthLogo {
	width: 134px;
	height: 44px;
}

.header .logo {
	margin-top: 6px;
	display: block;
	float: left;
	padding: 10px 20px;
	text-decoration: none;
}

.menu {
	display: flex;
	flex-direction: row;
}

/* menu */

.header .menu {
	clear: both;
	transition: max-height .2s ease-out;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
	.wrap {
		width: 824px;
		// gap: 35px;
	}

	.None {
		display: none;
	}
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
	.wrap {
		width: 580px;
		// gap: 35px;
	}

	.None {
		display: none;
	}

	.widthLogo {
		width: 89px;
		height: 32px;
	}

}

@media only screen and (min-width: 360px) and (max-width: 600px) {
	.wrap {
		display: flex;
		justify-content: center;
		width: 328px;
		// gap: 35px;
	}

	.None {
		display: none;
	}

	.buttons {
		display: none;

	}

	.widthLogo {
		width: 100px;
		height: 34px;
	}

}

// /* menu icon */

// .header .menuIcon {
//   cursor: pointer;
//   display: inline-block;
//   float: right;
//   padding: 28px 20px;
//   position: relative;
//   user-select: none;
// }

// .header .menuIcon .navicon {
//   background: #333;
//   display: block;
//   height: 2px;
//   position: relative;
//   transition: background .2s ease-out;
//   width: 18px;
// }

// .header .menuIcon .navicon:before,
// .header .menuIcon .navicon:after {
//   background: #333;
//   content: '';
//   display: block;
//   height: 100%;
//   position: absolute;
//   transition: all .2s ease-out;
//   width: 100%;
// }

// .header .menuIcon .navicon:before {
//   top: 5px;
// }

// .header .menuIcon .navicon:after {
//   top: -5px;
// }
// /* menu btn */

// .header .menuBtn {
//   display: none;
// }

// .header .menuBtn:checked ~ .menu {
//   max-height: 240px;
// }

// .header .menuBtn:checked ~ .menuIcon .navicon {
//   background: transparent;
// }

// .header .menuBtn:checked ~ .menuIcon .navicon:before {
//   transform: rotate(-45deg);
// }

// .header .menuBtn:checked ~ .menuIcon .navicon:after {
//   transform: rotate(45deg);
// }

// .header .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:before,
// .header .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:after {
//   top: 0;
// }

// /* 48em = 768px */

// @media only screen and (min-width: 600px) and (max-width: 1024px) {
//   .wrap {
//     width: 924px;
//     // gap: 35px;
//   }
//   .menu{
//     display: none;
//   }
// }
// @media (min-width: 360px) {
//   .header li {
//     float: left;
//   }
//   .header li a {
//     padding: 15px 15px;
//   }
//   // .header .menu {
//   //   clear: none;
//   //   float: right;
//   //   max-height: none;
//   //   display: flex;
//   // }
//   .header .menuIcon {
//     display: none;
//   }
// }