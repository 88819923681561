
.root {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.selected {
  font-size: 16px;
  color: #0f1e37;
  font-weight: 600;
}

.unSelected {
  font-size: 16px;
  color: #0f1e37;
  opacity: 0.5;
  font-weight: 600;
}

.button {
  cursor: pointer;
}

.flag {
  width: 109px;
  height: 46px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0px;
}
.ReactFlagsSelect-module_selectBtn {
  border-radius: 0px;
  display: none;
}

#rfs-btn {
  border-radius: 0px;
  display: none;
}

#lang {
  border-radius: 0px;
  width: 107px;
  height: 40px;
  border: 0;
  padding: 0;
  button {
    border-radius: 0px;
  }
}

.menuFlagsButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px;
  // border: 1px solid #0f1e37;
  background-color: #f5f5f5;
}
