@import "./../../../../styles/variables";

.section {
	display: flex;
	flex-direction: column;
	margin-bottom: 100px;

	@media (max-width: $tablet) {
		margin-bottom: 80px;
	}

}

.header {
	margin-bottom: 76px;

	@media (max-width: $tablet) {
		margin-bottom: 48px;
	}
}

.content {
	margin-bottom: 24px;
}

.footer {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;

	a {
		color: white;
	}
}